let serverAddress
// serverAddress = "https://zlotetarasy.s.sprin.tech/";
// serverAddress = "https://phytopharm.s.sprin.tech/";
// serverAddress = "https://s4.sprintechlearning.com/";
// serverAddress = "https://kpmg.s.sprin.tech/";
// serverAddress = "https://s.ricoh.sprin.tech/";
// serverAddress = "https://dev.s.sprin.tech/";
// serverAddress = "https://swieta.s.sprin.tech/";
// serverAddress = "https://demo.s.sprin.tech/";
// serverAddress = "https://hr.s.sprin.tech/"
// serverAddress = "https://sv-hr.sprin.tech/"
// serverAddress = "http://localhost:8888/"
// serverAddress = "http://192.168.0.4:8888/"
serverAddress = "https://sv.sprin.tech/"
// serverAddress = "https://santander-sv.sprin.tech/"
// serverAddress = "https://ge.sprintsbox.com/api/"
// serverAddress = "https://box.sprin.tech/api/"
// serverAddress = "https://s.sprin.tech/"

const CONFIG = {
  SERVER_ADDRESS: serverAddress,
  // INSTANCE_NAME: "hrsprintlearning",
  // INSTANCE_NAME: "canpack",
  // INSTANCE_NAME: "enge",
  // INSTANCE_NAME: "prostata",
  // INSTANCE_NAME: "suus",
  // INSTANCE_NAME: "prostate",
  // INSTANCE_NAME: "breastcancer",
  // INSTANCE_NAME: "rakpiersi",
  // INSTANCE_NAME: "rak-piersi-no-brand",
  // INSTANCE_NAME: "breast-cancer-gr",
  // INSTANCE_NAME: "breast-cancer-ro",
  // INSTANCE_NAME: "breast-cancer-hu",
  // INSTANCE_NAME: "values",
  // INSTANCE_NAME: "imposter",
  // INSTANCE_NAME: "szymon",
  // INSTANCE_NAME: "dysfunkcje",
  // INSTANCE_NAME: "phytopharm",
  // INSTANCE_NAME: "pueria",
  // INSTANCE_NAME: "example-pueria",
  // INSTANCE_NAME: "prostate-hu",
  // INSTANCE_NAME: "scanhr",
  // INSTANCE_NAME: "kuzniar-media",
  // INSTANCE_NAME: "culturebook",
  // INSTANCE_NAME: "pah-culture",
  // INSTANCE_NAME: "scanmed",
  // INSTANCE_NAME: "bos",
  // INSTANCE_NAME: "mega-trends",
  // INSTANCE_NAME: "wise-help",
  // INSTANCE_NAME: "example",
  // INSTANCE_NAME: "IG",
  // INSTANCE_NAME: "agilefulness",
  // INSTANCE_NAME: "feedback-digital-hr-champions",
  INSTANCE_NAME: "values",
  SESSION_ID: "215",

  NAME_TO_SESSION: {
    // smart: "199",
    // feedback: "198",
    // nastawienie: "197",
  },

  // LANGUAGE: "pl",
  LANGUAGE: "en",
  // LANGUAGE: "gr",
  // LANGUAGE: "ro",
  // LANGUAGE: "hu",

  // SESSION_ID: "200",

  // SESSION_ID: "226",
  // SESSION_ID: "225",

  // en
  // SESSION_ID: "201",
  // SESSION_ID: "215",
  // pl
  // SESSION_ID: "203",
  // SESSION_ID: "217",

  ANIMATION_SPEED_MS: 1000,
  TIME_TO_READ_FEEDBACK_MS: 6000,

  GA_ID: "UA-183086207-1", // CANPACK
  NONINVASIVE_CONSENT: true,
  SHOW_CONSENT: true,
  // SHOW_CONSENT: false,
}

export default CONFIG
